import React, { useContext, useEffect, useState } from 'react';
import { decreaseCartProductQuantity, fetchProductInCart, increaseCartProductQuantity } from '../../http/productAPI';
import '../../CSS/SCSS/commonComponents/AddCartRemove.scss';
import { Context } from '../..';

const AddCartRemove = ({ productId }) => {
    const [quantity, setQuantity] = useState(0);
    const user = useContext(Context);

    useEffect(() => {
        if (user.user.isAuth) {
            const getProductInCart = async () => {
                try {
                    const result = await fetchProductInCart(productId);
                    if (result.data) {
                        setQuantity(result.data.quantity); // Set the quantity after fetching
                    } else {
                        setQuantity(0);
                    }
                } catch (error) {
                    console.error('Error fetching product in cart:', error);
                }
            };
            // Call the function to fetch the product in the cart
            getProductInCart();
        }

    }, [productId]); // Run this effect whenever product.id changes

    const handleIncreaseQuantity = async () => {
        try {
          const data = await increaseCartProductQuantity(productId);
      
          if (!data.data[0][0].quantity) {
            alert('Quantity cannot be increased.');
          } else {
            setQuantity((prevQuantity) => prevQuantity + 1);
          }
        } catch (error) {
          console.error('Error increasing product quantity:', error);
        }
      };

    const handleDecreaseQuantity = async () => {
        const data = await decreaseCartProductQuantity(productId);
        if (quantity >= 1) {
            setQuantity((prevQuantity) => prevQuantity - 1);
        } else {
            alert('El CHKAAAA');
        }
    }

    return (
        <div className='add-cart-remove'>
            <button
                type='button'
                className='btn-count'
                onClick={handleDecreaseQuantity}
            >
                -
            </button>
            <span className='span-quantity'>{quantity}</span>
            <button
                type='button'
                className='btn-count'
                onClick={handleIncreaseQuantity}
            >
                +
            </button>
        </div>
    );
};

export default AddCartRemove;
