import React from 'react';
import { Button } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import '../../../CSS/SCSS/HomeTabCard.scss';
import { $host } from '../../../http';
import FavoriteHeart from '../FavoriteHeart';
import AddCartRemove from '../AddCartRemove';
import { Link } from 'react-router-dom';

const HomeTabCard = ({ product }) => {
    return (
        <>
            <div className="tab-card w-25" key={product.id}>
                <div className="tab-cart-header d-flex">
                    {product.sales && <div className="tab-cart-tags">sales</div>}
                    {product.discount && <div className="tab-cart-tags">{product.discount}%</div>}
                    {product.news && <div className="tab-cart-tags">news</div>}
                </div>
                <div className="card-image-container">
                    <Link to={`/product/${product.id}`}>
                        <img src={$host.defaults.baseURL + 'photos/products/' + product.img_main} alt="Product" className="card-image" />
                    </Link>
                    <span className="heart-icon">
                        <FavoriteHeart productId={product.id} />
                    </span>
                </div>
                <div className="card-content">
                    <div className="card-body">
                        <h3 className="card-title">{product.name}</h3>
                        <p className="card-price">${product.price} $</p>
                    </div>
                    <div className="card-footer">
                        {/* <Button className="add-to-cart-button w-100">Add to Cart</Button> */}
                        <AddCartRemove productId={product.id} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeTabCard;
