export function getRandomElementsFromArray(arr, numElements) {
    if (numElements >= arr.length) {
        // If numElements is greater than or equal to the array length,
        // return a shuffled copy of the entire array.
        const shuffledArray = [...arr];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    } else {
        // If numElements is less than the array length,
        // select random elements without replacement.
        const copyArray = [...arr];
        const randomElements = [];
        while (randomElements.length < numElements) {
            const randomIndex = Math.floor(Math.random() * copyArray.length);
            const selectedElement = copyArray.splice(randomIndex, 1)[0];
            randomElements.push(selectedElement);
        }
        return randomElements;
    }
}

//function to filter array by property
export function filterArrayByProperty(arr, propToFilterBy, filterValue) {
    return arr.filter(item => item[propToFilterBy] === filterValue);
}