import React, { useEffect, useState } from 'react';
import CartPageItem from '../components/commonComponents/CartPageItem';
import '../CSS/SCSS/pages/Cart.scss';
import { createOrder, fetchCartProductsByCartId } from '../http/productAPI';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PaymentMethod from '../components/commonComponents/PaymentMethod';
import OrderModal from '../components/modalComponents/OrderModal';

const Cart = () => {
    const [cartProducts, setCartProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [show, setShow] = useState(false);
    const [orderProducts, setOrderProducts] = useState([]);
    const [productIds, setProductIds] = useState([]);
    const [quantities, setQuantities] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    // Function to handle the selected payment method
    const handleSelectPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
    };
    // 
    useEffect(() => {
        // Add an event listener to the window's beforeunload event
        const handleBeforeUnload = () => {
            // Clear localStorage when the page is reloaded
            localStorage.removeItem('productArray');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    //   

    // Function to fetch cart products
    async function fetchCartData() {
        try {
            const cartData = await fetchCartProductsByCartId();
            setCartProducts(cartData);
        } catch (error) {
            console.error('Error fetching cart products:', error);
        }
    }

    async function confirmOrder(productIds, quantities) {
        try {
            if (!selectedPaymentMethod) {
                alert('Please choose Payment Method')
            } else {
                const response = await createOrder(productIds, quantities, selectedPaymentMethod);
                console.log('Order created:', response);
                fetchCartData();
                //Add Banking payment if selectedPaymentMethod is card
                setProductIds([]);
                setQuantities([]);
                setOrderProducts([]);
                // Clear productArray
                localStorage.removeItem('productArray');
                alert('Your order was successfully confirmed');
                handleClose()
            }

        } catch (error) {
            console.error('Error creating order:', error);
        }
    }

    const handleCreateOrder = () => {
        let productArray = JSON.parse(localStorage.getItem('productArray')) || [];
        const newProductIds = [];
        const newQuantities = [];

        // Loop through productArray and populate the arrays
        productArray.forEach((product) => {
            newProductIds.push(product.productId);
            newQuantities.push(product.quantity);
        });

        // Update the state with the new values
        setProductIds(newProductIds);
        setQuantities(newQuantities);

        const filteredItems = cartProducts.filter(orderProduct => newProductIds.includes(orderProduct.productId));
        setOrderProducts(filteredItems)
        handleShow()

    };

    // Initial fetch when the component mounts
    useEffect(() => {
        fetchCartData();
    }, []);
    // Effect to calculate total and quantity when cartProducts change
    useEffect(() => {
        let newTotal = 0;
        let newQuantity = 0;
        cartProducts.forEach((product) => {
            newTotal += product.price * product.quantity;
            newQuantity += 1; // You might need to adjust this based on your cart data structure
        });
        setTotal(newTotal);
        setQuantity(newQuantity);
    }, [cartProducts]);

    return (
        <>
            <div className='cart-main'>
                <div className='cart-items'>
                    {cartProducts.map((product, index) => (
                        <CartPageItem key={index} product={product} />
                    ))}
                </div>
                <div className='cart-information'>
                    <h3>In the cart</h3>
                    <p className='cart-count'>Products <span>{quantity}</span></p>
                    <button className='btn-promo-code'>Enter Promo please</button>
                    <p className='cart-total'>{total} $</p>
                    <button className='bnt-order' onClick={handleCreateOrder}>Order</button>
                </div>
            </div>
            <OrderModal
                show={show}
                handleClose={handleClose}
                orderProducts={orderProducts}
                productIds={productIds}
                quantities={quantities}
                confirmOrder={confirmOrder}
            />
        </>
    );
};

export default Cart;
