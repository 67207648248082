import React, { useEffect, useState } from 'react';
import { fetchUserFavorites } from '../http/productAPI';
import FavoritePageItem from '../components/commonComponents/FavoritePageItem';

const FavoritesPage = () => {

    const [favoriteProducts, setFavoriteProducts] = useState([]);

    useEffect(() => {
        async function fetchFavorites () {
            try {
                const favorites = await fetchUserFavorites();
            setFavoriteProducts(favorites.data);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            }
        }
        fetchFavorites();
    },[]);

    return (
        <div>
            <h2 className='page-title'>FAVORITES</h2>
            {favoriteProducts.map((favoriteProduct, index) => {
                return <FavoritePageItem product={favoriteProduct} key={index}/>
            })}
        </div>
    );
};

export default FavoritesPage;