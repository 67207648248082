import React, { useState } from 'react';
import '../../CSS/SCSS/commonComponents/CartPageItem.scss';
import { FaTrash } from 'react-icons/fa';
import { $authHost } from '../../http';
import AddCartRemove from './AddCartRemove';
import FavoriteHeart from './FavoriteHeart';
import { deleteCartProduct } from '../../http/productAPI';

const CartPageItem = ({ product }) => {

    // const [productId, setProductId] = useState(null);
    // const [quantity, setQuantity] = useState(null);
    const [isChecked, setIsChecked] = useState(false); // Initialize checkbox state

    const handleCheckboxChange = () => {
        // Toggle the checkbox state
        setIsChecked(!isChecked);
    
        // get the existing productArray from localStorage
        let productArray = JSON.parse(localStorage.getItem('productArray')) || [];
    
        if (!isChecked) {
            // Add the current product's productId and quantity to the array
            productArray.push({ productId: product.productId, quantity: product.quantity });
    
            // Set the productId and quantity state variables
            // setProductId(product.productId);
            // setQuantity(product.quantity);
        } else {
            // Remove the current product's entry from the array if unchecked
            productArray = productArray.filter(item => item.productId !== product.productId);
    
            // Clear the productId and quantity state variables
            // setProductId(null);
            // setQuantity(null);
        }
    
        // Serialize the modified array and update it in localStorage
        localStorage.setItem('productArray', JSON.stringify(productArray));
    };
    
    const handleDelete = async (productId) => {
        try {
          const data = await deleteCartProduct(productId);
          console.log(`Product with ID ${productId} deleted successfully`);
        } catch (error) {
          console.error('Error deleting product:', error);
        }
      };

    return (
        <div className='cart-page-item'>
            <div className='cart-item-image'>
                <img src={$authHost.defaults.baseURL + 'photos/products/' + product.image} alt='cherevac' />
            </div>
            <div className='cart-item-details'>
                <div className='cart-item-information'>
                    <p className='cart-item-name'>{product.product}</p>
                    <p className='cart-item-image'>{product.image}</p>
                    <p className='cart-item-price'>{product.price} $</p>
                </div>
                <div className='cart-item-btns'>
                    <div className='cart-item-delete'>
                        <FavoriteHeart productId={product.productId} />
                        <button
                            onClick={() => handleDelete(product.productId)}
                            className='btn-delete'
                        >
                            <FaTrash />
                        </button>
                    </div>
                    <AddCartRemove productId={product.productId} />
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CartPageItem;