import React, { useState } from 'react';

const CheckBoxComponent = ({ text, onChange }) => {
    // Local state to manage the checkbox value
    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox changes
    const handleCheckboxChange = (event) => {
        const newValue = event.target.checked;
        setIsChecked(newValue);

        // Call the onChange callback provided by the parent component
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                {text}
            </label>
        </div>
    );
};

export default CheckBoxComponent;
