import React from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { CART_ROUTE } from '../../../utils/consts';
import '../../../CSS/SCSS/commonComponents/navbarComponents/NavbarCartComponent.scss';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NavbarCartComponent = ({ cartProductsCount, cartId }) => {
    const history = useNavigate();
    return (
        <Button
            id='btnCart'
            className='btn-nav-icons'
            variant='outline-dark'
            onClick={() => history(CART_ROUTE + '/' + cartId)}
        >
            <FaCartPlus />
            <span className='span-cart-product-count'>{cartProductsCount}</span>
        </Button>
    );
};

export default NavbarCartComponent;