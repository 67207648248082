import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HomeTabCard from './HomeTabCard';
import { fetchProducts } from '../../../http/productAPI';
import { filterArrayByProperty, getRandomElementsFromArray } from '../../../utils/helpers';

const HomeTab = () => {
    const [key, setKey] = useState('hits');
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProducts()
            .then(response => {
                setProducts(response.rows);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const hotProducts = filterArrayByProperty(products, 'tagHot', true);
    const topProducts = filterArrayByProperty(products, 'tagTop', true);
    const newProducts = filterArrayByProperty(products, 'tagNew', true);
    
    const randomThreeHotProducts = getRandomElementsFromArray(hotProducts, 4);
    const randomThreeTopProducts = getRandomElementsFromArray(topProducts, 3);
    const randomThreeNewProducts = getRandomElementsFromArray(newProducts, 3);

    return (
        <Tabs
            id="controlled-tab-home"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="hits" title="Hits">
                <div className='d-flex'>
                    {randomThreeHotProducts.map((product) => (
                        <HomeTabCard key={product.id} product={product} />
                    ))}
                </div>
            </Tab>
            <Tab eventKey="top" title="Top">
                <div className='d-flex'>
                    {randomThreeTopProducts.map((product) => (
                        <HomeTabCard key={product.id} product={product} />
                    ))}
                </div>
            </Tab>
            <Tab eventKey="news" title="News">
                <div className='d-flex'>
                    {randomThreeNewProducts.map((product) => (
                        <HomeTabCard key={product.id} product={product} />
                    ))}
                </div>
            </Tab>
        </Tabs>
    );
};

export default HomeTab;
