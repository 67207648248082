import React, { useState, useEffect } from 'react';

const SelectComponent = ({ chooseText, items, onSelectChange, reset }) => {
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    // If the reset prop changes, clear the selected value
    if (reset) {
      setSelectedValue('');
    }
  }, [reset]);

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;

    // Call the onSelectChange callback with the selected option's value
    if (onSelectChange) {
      onSelectChange(selectedOption);
    }

    // Update the selected value in the component's state
    setSelectedValue(selectedOption);
  };

  return (
    <select
      className='select-component'
      onChange={handleOptionChange}
      value={selectedValue}
    >
      <option value="">
        {chooseText}
      </option>
      {items.map((item, index) => {
        return <option key={index} value={item}>{item}</option>;
      })}
    </select>
  );
};

export default SelectComponent;
