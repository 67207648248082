import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import SelectComponent from '../components/commonComponents/SelectComponent';
import RangeInput from '../components/commonComponents/RangeInput';
import ProductCard from '../components/ProductCard';
import '../CSS/SCSS/pages/Catalog.scss';
import { Link, useParams } from 'react-router-dom';
import { fetchBrands, fetchOneBrand, fetchOneCategory, fetchProducts } from '../http/productAPI';
import BrandSelectorComponent from '../components/commonComponents/BrandSelectorComponent';
import PaginationComponent from '../components/commonComponents/PaginationComponent';
import PerPageComponent from '../components/commonComponents/PerPageComponent';
import CheckBoxComponent from '../components/commonComponents/CheckBoxComponent';

const Catalog = () => {

    const { id } = useParams();

    const categoryId = id;
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [brands, setBrands] = useState([]);
    const [brandId, setBrandId] = useState('');
    const [limit, setLimit] = useState(3);
    const [page, setPage] = useState(null);
    const [attribute, setAttribute] = useState(''); // for sorting
    const [order, setOrder] = useState(''); //
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [popular, setPopular] = useState(null);
    const [hot, setHot] = useState(null);
    const [news, setNews] = useState(null);
    const [top, setTop] = useState(null);
    const [max, setMax] = useState();
    const [count, setCount] = useState(1);

    const options = {
        categoryId,
        brandId,
        limit,
        page,
        attribute,
        order,
        minPrice,
        maxPrice,
        discount,
        popular,
        hot,
        news,
        top,
    };
    //Consts may be not here
    const sorting = ['price', 'weight', 'quantity', 'name'];
    const asc = ['asc', 'desc'];
    // Reset Part
    const [reset, setReset] = useState(false);

    const handleInputChange = (stateSetter) => (value) => {
        stateSetter(value);
    };

    // Usage in your component
    const handleAttributeChange = handleInputChange(setAttribute);
    const handleOrderChange = handleInputChange(setOrder);
    const handleMinPriceChange = handleInputChange(setMinPrice);
    const handleMaxPriceChange = handleInputChange(setMaxPrice);
    const handleBrandChange = handleInputChange(setBrand);
    const handleBrandIdChange = handleInputChange(setBrandId);
    const handlePageChange = handleInputChange(setPage);
    const handleLimitChange = handleInputChange(setLimit);
    const handleSetDiscountChange = handleInputChange(setDiscount);
    const handleSetPopularChange = handleInputChange(setPopular);
    const handleSetHotChange = handleInputChange(setHot);
    const handleSetNewsChange = handleInputChange(setNews);
    const handleSetTopChange = handleInputChange(setTop);

    const handleReset = () => {
        setAttribute('');
        setOrder('asc');
        setReset(true);
        setMinPrice(0);
        setMaxPrice(max);
    }

    useEffect(() => {
        //UPGRADE kastil?
        if (reset) {
            setReset(false)
        }
        // Fetch maximum product price
        fetchProducts()
            .then(response => {
                const prices = (response.rows).map(product => product.price);
                // Use the reduce function to find the maximum price value
                const maxProductPrice = prices.reduce((max, currentPrice) => {
                    if (currentPrice > max) {
                        return currentPrice;
                    }
                    return max;
                }, 0);
                setMax(maxProductPrice);
            })
            .catch(error => {
                console.log(error)
            });

        // Fetch category information
        if (categoryId) {
            fetchOneCategory(categoryId)
                .then(response => {
                    setCategory(response.name);
                })
                .catch(error => {
                    console.error('Error fetching category:', error);
                });
        }

        // Fetch brand information
        fetchBrands()
            .then(response => {
                setBrands(response);
            })
            .catch(error => {
                console.log(error);
            });

        // Fetch products
        try {
            fetchProducts(options)
                .then(response => {
                    if (response.rows) {
                        setProducts(response.rows)
                        setCount(response.count);
                    }
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                });

        } catch (error) {
            alert(error)
        }
    }, [categoryId, brandId, limit, page, attribute, order, minPrice, maxPrice, discount, top, popular, hot, news]);


    return (
        <div className='catalog-main'>
            <h3 className='page-title'>
                {categoryId ? `products from category ${category}` : 'Catalog'}
            </h3>
            <div className='sorting-and-filters'>
                <div className='sorting-filter-header'>
                    <p>
                        <span>products {products.length}</span>
                    </p>
                    <div>
                        <Button
                            onClick={() => handleReset()}
                            className='mx-3'
                            variant='outline-primary'>
                            Reset
                        </Button>
                        <Link to={'/catalog'}>
                            <Button variant='outline-primary'>All Categories</Button>
                        </Link>
                    </div>
                </div>
                <div className='sorting-filter-items'>
                    <div className='sorting-filter-item'>
                        <SelectComponent
                            chooseText='Sort By'
                            items={sorting}
                            onSelectChange={handleAttributeChange}
                            reset={reset}
                        />
                    </div>
                    <div className='sorting-filter-item'>
                        <BrandSelectorComponent
                            brands={brands}
                            onBrandChange={handleBrandChange}
                            onBrandIdChange={handleBrandIdChange}
                            reset={reset}
                        />
                    </div>
                    <div className='sorting-filter-item'>
                        <SelectComponent
                            chooseText='A to Z'
                            items={asc}
                            showArrow={true}
                            onSelectChange={handleOrderChange}
                            reset={reset}
                        />
                    </div>
                    <div className='sorting-filter-item checkbox-filters'>
                        <CheckBoxComponent
                            text={'Discount'}
                            onChange={handleSetDiscountChange}
                        />
                        <CheckBoxComponent
                            text={'Popular'}
                            onChange={handleSetPopularChange}
                        />

                        <CheckBoxComponent
                            text={'Hot'}
                            onChange={handleSetHotChange}
                        />

                        <CheckBoxComponent
                            text={'News'}
                            onChange={handleSetNewsChange}
                        />

                        <CheckBoxComponent
                            text={'Top'}
                            onChange={handleSetTopChange}
                        />
                    </div>
                    {max ?
                        <div className='sorting-filter-item'>
                            <RangeInput
                                max={max}
                                onMinPriceChange={handleMinPriceChange}
                                onMaxPriceChange={handleMaxPriceChange}
                                reset={reset}
                            />
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
            <div className='catalog-content'>
                {products.length ? (
                    products.map((product, index) => {
                        return <ProductCard key={index} product={product} />;
                    })
                ) : (
                    <p className='text-no-products'>There is no such Products</p>
                )}
            </div>
            <div className='catalog-pagination'>
                <PaginationComponent count={count} limit={limit} onActiveChange={handlePageChange} />
                <PerPageComponent onActiveChange={handleLimitChange} />
            </div>
        </div>
    );
};

export default Catalog;