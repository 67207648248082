import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import Pagination from "react-bootstrap/Pagination";

const PerPageComponent = ({ onActiveChange }) => {

    const [active, setActive] = useState(0);
    //how many items show on page
    const items = [4, 8, 12];

    const handlePageClick = (number) => {
        setActive(number);
        onActiveChange(items[number]);
    };
    return (
        <div>
            <Pagination>
            <span className='outline-primary'>Products per page</span>
            {/* <Badge bg="primary">Primary</Badge> */}
            {items.map((item, number) => (
                <Pagination.Item key={number} active={active === number} onClick={() => handlePageClick(number)}>
                    {item}
                </Pagination.Item>
            ))}
        </Pagination>
        </div>
    );
};

export default PerPageComponent;