import React, { useContext, useEffect, useRef, useState } from 'react';
import '../CSS/SCSS/pages/ProductPage.scss';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { createOrder, fetchOneProduct } from '../http/productAPI';
import { $host } from '../http';
import { Context } from '..';
import FavoriteHeart from '../components/commonComponents/FavoriteHeart';
import { toJS } from 'mobx';
import AddCartRemove from '../components/commonComponents/AddCartRemove';
import OrderModal from '../components/modalComponents/OrderModal';

const ProductPage = () => {

    const [product, setProduct] = useState({});
    const myProductId = useParams().id;

    const { user } = useContext(Context);

    const [show, setShow] = useState(false);
    const [orderProducts, setOrderProducts] = useState([]);
    const [productIds, setProductIds] = useState([]);
    const [quantities, setQuantities] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        fetchOneProduct(myProductId)
            .then((response) => {
                setProduct(response.data);
            })
            .catch((error) => {
                console.error('Error fetching product:', error);
            });
    }, [myProductId]);

    let productImages = [
        product.img_main,
        product.img_front,
        product.img_back,
        product.img_left,
        product.img_right,
    ]

    const [activeImage, setActiveImage] = useState(productImages[0]);

    const productDescriptionRef = useRef(null);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const description = (product.info && product.info.length) ? product.info[0].description : 'Նկարագրություն չկա';
    const descriptionToShow = showFullDescription ? description : description.split('\n')[0];
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    // Function to handle the selected payment method
    const handleSelectPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
    };

    async function confirmOrder(productIds, quantities) {
        try {
            if (!selectedPaymentMethod) {
                alert('Please choose Payment Method')
            } else {
                const response = await createOrder(productIds, quantities, selectedPaymentMethod);
                console.log(response);
                setProductIds([]);
                setQuantities([]);
                setOrderProducts([]);
                // Clear productArray
                localStorage.removeItem('productArray');
                alert('Your order was successfully confirmed');
                handleClose()
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
    }

    const handleCreateOrder = () => {
        // Update the state with the new values
        setProductIds([product.id]);
        setQuantities([1]);
        let orderProduct = product;
        orderProduct.product = orderProduct.name
        orderProduct.quantity = 1;
        if(orderProduct.quantity){
            setOrderProducts([orderProduct]);
            handleShow();
        }
    };

    const handleShowMore = () => {
        setShowFullDescription(true);
        productDescriptionRef.current.style.height = 'auto';
    };

    const handleShowLess = () => {
        setShowFullDescription(false);
        productDescriptionRef.current.style.height = '26px';
    };

    const sliderElements = productImages.map((image, index) => {
        if (image !== activeImage) {
            return (
                <img
                    src={$host.defaults.baseURL + '/photos/products/' + image}
                    alt='sliderPhoto'
                    className='product-slider-image'
                    key={index}
                    onClick={() => setActiveImage(image)}
                />
            );
        }
        return null;
    });

    return (
        <>
            <div className='product-main'>
                <div className='product-view'>
                    <div className='product-tags'>
                        {product.sells && <div className="product-tag">sells</div>}
                        {product.discount && <div className="product-tag">{product.discount}%</div>}
                        {product.news && <div className="product-tag">news</div>}
                    </div>
                    <div className='product-image'>
                        <img className='product-img' src={$host.defaults.baseURL + '/photos/products/' + activeImage} alt='product' />
                        <div className='product-slider'>
                            {sliderElements}
                        </div>
                    </div>
                </div>
                <div className='product-information'>
                    <div className='product-title'>
                        <h2>{product.name}</h2>
                        <p>{product.price} $</p>
                    </div>
                    <div className='product-buy'>
                        {/* <Button>
                            <FaCartPlus />
                            Add to Cart
                        </Button> */}
                        <AddCartRemove productId={product.id} />
                        {user.isAuth ? <FavoriteHeart userId={toJS(user.user.id)} productId={myProductId} /> : ''}
                        <Button variant='outline-danger' onClick={handleCreateOrder}>
                            Buy one Click
                        </Button>
                    </div>
                    <div className="product-short-description">
                        <div className="static-text">
                            <p>
                                <span>{product.shortDescription}</span>
                            </p>
                        </div>
                    </div>
                    <div className='product-description'>
                        <h4>Description</h4>
                        <p ref={productDescriptionRef} id='productDescriptionText'>
                            {descriptionToShow}
                        </p>
                        {!showFullDescription ? (
                            <Button variant='outline-success' className='btn-show-all' onClick={handleShowMore}>
                                Show more
                            </Button>
                        ) : (
                            <Button variant='outline-success' className='btn-show-all' onClick={handleShowLess}>
                                See less
                            </Button>
                        )}
                    </div>
                    <div className='product-properties'>
                        <h4>Properties</h4>
                        <div className='product-properties-content'>
                            {/* Here can be object or array of properties and map it in future */}
                            <div className='product-country'>
                                <p className='property-title'>made in</p>
                                <p className='property-item'>{product.country}</p>
                            </div>
                            <div className='product-size'>
                                <p className='property-title'>Weight</p>
                                <p className='property-item'>{product.weight}</p>
                            </div>
                            <div className='product-code'>
                                <p>product code</p>
                                <p>{product.code}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrderModal
                show={show}
                handleClose={handleClose}
                orderProducts={orderProducts}
                handleSelectPaymentMethod={handleSelectPaymentMethod}
                productIds={productIds}
                quantities={quantities}
                confirmOrder={confirmOrder}
            />
        </>
    );
};

export default ProductPage;