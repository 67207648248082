export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const HOME_ROUTE = '/'
export const TEST_ROUTE = '/test'
export const BASKET_ROUTE = '/basket'
export const PRODUCT_ROUTE = '/product'
export const CATALOG_ROUTE = '/catalog'
export const CART_ROUTE = '/cart'
export const FAVORITE_ROUTE = '/favorite'
export const NEWORDER_ROUTE = '/new_order'