import React from 'react';
import { $authHost } from '../../http';
import FavoriteHeart from './FavoriteHeart';
import AddCartRemove from './AddCartRemove';
import '../../CSS/SCSS/commonComponents/FavoritePageItem.scss';

const FavoritePageItem = ({ product }) => {
    return (
        <div className='favorite-page-item'>
            <div className='favorite-item-image'>
                <img src={$authHost.defaults.baseURL + 'photos/products/' + product.img_main} alt='cherevac' />
            </div>
            <div className='favorite-item-details'>
                <div className='favorite-item-information'>
                    <p className='favorite-item-name'>{product.product}</p>
                    {/* <p className='favorite-item-image'>{product.img_main}</p> */}
                    <p className='favorite-item-price'>{product.price} $</p>
                </div>
                <div className='favorite-item-btns'>
                    <div className='favorite-item-delete'>
                        <FavoriteHeart productId={product.id} />
                        {/* <button
                            onClick={() => handleDelete(product.productId)}
                            className='btn-delete'
                        >
                            <FaTrash />
                        </button> */}
                    </div>
                    <AddCartRemove productId={product.id} />
                </div>
            </div>
        </div>
    );
};

export default FavoritePageItem;