import React, { useContext, useEffect, useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { addFavorite, isFavorite, removeFavorite } from '../../http/productAPI';
import '../../CSS/SCSS/commonComponents/FavoriteHeart.scss';
import { Context } from '../..';

const FavoriteHeart = ({ productId }) => {

    const [favorite, setFavorite] = useState(false);
    const user = useContext(Context);

    useEffect(() => {
        if (user.user.isAuth) {
            isFavorite(productId)
                .then(response => setFavorite(response.data.isFavorite))
                .catch(error => console.error('Error checking favorite status:', error));
        }
    }, [productId]);

    const handleFavoriteClick = async () => {
        try {
            if (favorite) {
                await removeFavorite(productId);
                console.log('Removed from favorites');
            } else {
                await addFavorite(productId);
                console.log('Added to favorites');
            }
            setFavorite(!favorite); // Toggle the favorite state
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <button className={`favorite-heart ${favorite ? 'active-heart' : ''}`} onClick={handleFavoriteClick}>
            <FaHeart />
        </button>
    );
};

export default FavoriteHeart;