import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import OrderModalItem from '../commonComponents/OrderModalItem';
import PaymentMethod from '../commonComponents/PaymentMethod';

const OrderModal = ({
  show,
  handleClose,
  orderProducts,
  handleSelectPaymentMethod,
  productIds,
  quantities,
  confirmOrder,
}) => {
  
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {orderProducts.length ? (
          <div>
            <div className="order-modal-header">
              <span>Product</span>
              <span>Name</span>
              <span>Price</span>
              <span>Quantity</span>
              <span className="order-modal-header-total">Total</span>
            </div>
            {orderProducts.map((orderProduct) => (
              <OrderModalItem product={orderProduct} key={orderProduct.id} />
            ))}
            <div className="order-grandtotal">
              {orderProducts.reduce((total, orderProduct) => {
                const productTotal = orderProduct.price * orderProduct.quantity;
                return total + productTotal;
              }, 0)}
            </div>
            <PaymentMethod onSelectPaymentMethod={handleSelectPaymentMethod} />
          </div>
        ) : (
          <p>There are no products in the order.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => confirmOrder(productIds, quantities)}>
          Confirm Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
