import './App.css';
import AppRouter from '../src/components/AppRouter';

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
