import React from 'react';
import { Col } from 'react-bootstrap';
import { $host } from '../../http';
import { Link } from 'react-router-dom';

const CatalogCards = ({ categories }) => {

    return (
        categories.map((category, index) => {
            return (
                <Col xs={6} md={4} key={index}>
                    <Link to={`/catalog/${category.id}`}>
                        <img className='w-100' src={$host.defaults.baseURL + '/photos/categories/' + category.img} alt='nkar' />
                    </Link>
                    <h3>{category.name}</h3>
                </Col>
            )
        })
    );
};

export default CatalogCards;