import React, { useState, useEffect } from 'react';
import '../../CSS/SCSS/RangeInput.scss';

const RangeInput = ({ max, onMinPriceChange, onMaxPriceChange, reset }) => {
    const [lowerValue, setLowerValue] = useState(0);
    const [upperValue, setUpperValue] = useState(max);
    const min = 0;
    const step = 50;

    const handleLowerChange = (event) => {
        if (lowerValue < upperValue) {
            const value = parseInt(event.target.value);
            setLowerValue(value - 50);
            onMinPriceChange(value);
        } else {
            setUpperValue(lowerValue + 50);
        }
    };

    const handleUpperChange = (event) => {
        if (upperValue > lowerValue) {
            const value = parseInt(event.target.value);
            setUpperValue(value + 50);
            onMaxPriceChange(value);
        } else {
            setLowerValue(upperValue - 50);
        }
    };

    useEffect(() => {
        // Reset the values when the reset prop changes
        if (reset) {
            setLowerValue(0);
            setUpperValue(max);
        }
    }, [reset, max]);

    return (
        <div className="range-inputs">
            <input type="range" min={min} max={max} value={lowerValue} id="lowerRange" onChange={handleLowerChange} step={step} />
            <input type="range" min={min} max={max} value={upperValue} id="upperRange" onChange={handleUpperChange} step={step} />
            <div className="values">
                <span id="lowerValue">From {lowerValue} </span>
                <span id="upperValue"> to {upperValue}</span>
            </div>
        </div>
    );
};

export default RangeInput;
