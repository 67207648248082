import React, { useRef, useEffect } from 'react';

const BrandSelectorComponent = ({ brands, onBrandChange, onBrandIdChange, reset }) => {
    const selectRef = useRef(null);

    const handleOptionChange = (event) => {
        const selectElement = event.target;
        onBrandChange(selectElement.value);
        onBrandIdChange(selectElement.options[selectElement.selectedIndex].getAttribute("data-id"));
    };

    useEffect(() => {
        if (reset) {
            // Reset the selected index to 0
            selectRef.current.selectedIndex = 0;
            // Reset brand and brandId in the parent component
            onBrandChange('');
            onBrandIdChange('');
        }
    }, [reset, onBrandChange, onBrandIdChange]);

    return (
        <select
            className='brand-select-component'
            onChange={handleOptionChange}
            ref={selectRef}
        >
            <option data-id={0}>
                Please select a brand
            </option>
            {brands.map((brand) => {
                return <option data-id={brand.id} key={brand.id}>{brand.name}</option>;
            })}
        </select>
    );
};

export default BrandSelectorComponent;
