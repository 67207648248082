import React from 'react';
import { $authHost } from '../../http';
import '../../CSS/SCSS/commonComponents/OrderModalItem.scss';

const OrderModalItem = ({ product }) => {
    return (
        <div className='order-modal-item'>
            <img src={$authHost.defaults.baseURL + 'photos/products/' + product.image} alt='cherevac' />
            <span>{product.product}</span>
            <span>{product.price}</span>
            <span>{product.quantity}</span>
            <span className='order-modal-item-total'>{product.price*product.quantity}</span>
        </div>
    );
};

export default OrderModalItem;