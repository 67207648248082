import { $host, $authHost } from ".";

export const createProduct = async (product) => {
    const { data } = await $host.post('api/product', product, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return data
}

export const fetchProducts = async (options) => {
    const params = {};

    const optionProperties = [
        'categoryId',
        'brandId',
        'limit',
        'page',
        'attribute',
        'order',
        'minPrice',
        'maxPrice',
        'discount',
        'popular',
        'hot',
        'news',
        'top',
    ];

    if(options){
        optionProperties.forEach((property) => {
            if (options[property]) {
                params[property] = options[property];
            }
        });
    }

    const response = await $host.get('api/product', { params });
    return response.data;
};

export const fetchOneProduct = async (id) => {
    const data = await $host.get('api/product/' + id)
    return data
}

export const updateProduct = async (productId, updatedProduct) => {
    const { data } = await $authHost.put(`api/product/${productId}`, updatedProduct, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
    return data;
};

export const fetchCarts = async () => {
    const { data } = await $host.get('api/cart')
    return data
}

export const fetchOneCart = async (id) => {
    const { data } = await $host.get('api/cart/' + id)
    return data
}

export const createOrder = async ( productIds, quantities, paymentMethod) => {
    const { data } = await $authHost.post('api/order', { 'productIds': productIds, 'quantities': quantities, 'paymentMethod':paymentMethod })
    return data
}

export const fetchOrders = async () => {
    const { data } = await $host.get('api/order')
    return data
}

export const fetchOneOrder = async (id) => {
    const data = await $host.get('api/order/' + id)
    return data
}

export const createCategory = async (category) => {
    const { data } = await $authHost.post('api/category', { 'name': category })
    return data
}

export const fetchCategories = async () => {
    const { data } = await $host.get('api/category')
    return data.categories
}

export const fetchOneCategory = async (id) => {
    const { data } = await $host.get('api/category/' + id)
    return data
}

export const createBrand = async (brand) => {
    const { data } = await $authHost.post('api/brand', { 'name': brand })
    return data
}

export const fetchBrands = async () => {
    const { data } = await $host.get('api/brand')
    return data.brands
}

export const fetchOneBrand = async (id) => {
    const { data } = await $host.get('api/brand/' + id)
    return data
}

export const createCartProduct = async (cartId, productId, quantity, price) => {
    const { data } = await $host.post('api/cart_product', { 'cartId': cartId, 'productId': productId, 'quantity': quantity, 'price': price })
    return data
}
// only for quantity ---> NOT USED UPGRADE
export const updateCartProduct = async (id, quantity) => {
    const { data } = await $authHost.put('api/cart_product/' + id, { 'quantity': quantity })
    return data
}

export const increaseCartProductQuantity = async (productId) => {
    try {
        const { data } = await $authHost.put('api/cart_product/product/' + productId + '/increase');
        return data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                alert('You are not authenticated. Please log in.');
                window.location.href = '/login'; // Redirect to login page
            } else {
                console.error('Request error:', error.response.data);
            }
        } else {
            console.error('Network error:', error.message);
        }
        throw error; // Rethrow the error to propagate it to the caller if needed
    }
};

export const decreaseCartProductQuantity = async (productId) => {
    try {
        const { data } = await $authHost.put('api/cart_product/product/' + productId + '/decrease');
        return data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                // Unauthorized error, redirect to login page
                window.location.href = '/login';
            } else {
                // Log other error responses
                console.error('Request error:', error.response.data);
            }
        } else {
            // Network error, log the error message
            console.error('Network error:', error.message);
        }
        throw error; // Rethrow the error to propagate it to the caller if needed
    }
};

export const fetchProductInCart = async (productId) => {
    try {
        const { data } = await $authHost.get('api/cart_product/cart/product/' + productId)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const checkProductExistsInCart = async (cartId, productId) => {
    const { data } = await $authHost.get('api/cart_product/carts/' + cartId + '/products/' + productId + '/quantity')
    return data
}

export const fetchCartProductsByCartId = async () => {
    try {
        const { data } = await $authHost.get('api/cart_product/cart');
        // Create a new array based on the fetched data
        const newCartProducts = [...data];
        return newCartProducts;
    } catch (error) {
        console.error('Error fetching cart products:', error);
        throw error;
    }
};

export const deleteCartProduct = async (productId) => {
    try {
        const { data } = await $authHost.delete('api/cart_product/'+productId)
        return data
    } catch (error) {
        console.error('Error deleting cart product:', error);
        throw error;
    }
}

export const addFavorite = async (id) => {
    try {
        const { data } = await $authHost.post(`api/favorite/user/product/${id}/add`);
        return data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // UPGRADE kastil
            window.location.href = '/login';
          } else {
            console.error('Error adding product to favorites:', error);
          }
        }
};

export const removeFavorite = async (id) => {
    try {
        const { data } = await $authHost.delete(`api/favorite/user/product/${id}/remove`);
        return data
    } catch (error) {
        console.error('Error adding product to favorites:', error);
        throw error;
    }

};

export const fetchUserFavorites = async () => {
    try {
        const { data } = await $authHost.get('api/favorite/user')
        return data
    } catch (error) {
        console.error('Error fetching user`s a favorites:', error);
        throw error;
    }

}

export const isFavorite = async (id) => {
    try {
        const { data } = await $authHost.get(`api/favorite/user/product/${id}/is_favorite`);
        return data;
    } catch (error) {
        console.error('Error checking if product is a favorite:', error);
        throw error;
    }
};
