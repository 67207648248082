import React, { useState } from 'react';
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({ count, limit, onActiveChange }) => {
    const [active, setActive] = useState(1);
    let items = [];
    const handlePageClick = (number) => {
        setActive(number);
        onActiveChange(number);
    };
    for (let number = 1; number <= Math.ceil(count / limit); number++) {
        items.push(
            <Pagination.Item key={number} active={active === number} onClick={() => handlePageClick(number)}>
                {number}
            </Pagination.Item>
        );
    }
    return (
        <>
            <Pagination>{items}</Pagination>
        </>
    );
};

export default PaginationComponent;