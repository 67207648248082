import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Container, Nav, Button, InputGroup, Form } from 'react-bootstrap';
import { ADMIN_ROUTE, CART_ROUTE, FAVORITE_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';
import { Context } from '..';
import CatalogModal from './modalComponents/CatalogModal';
import { FaSearch, FaHeart, FaUser, FaCartPlus } from 'react-icons/fa';
import '../CSS/SCSS/NavBar.scss';
import { toJS } from 'mobx';
import jwtDecode from 'jwt-decode';
import { fetchCartProductsByCartId, fetchCategories } from '../http/productAPI';
import NavbarCartComponent from './commonComponents/navbarComponents/NavbarCartComponent';

const NavBar = () => {
  const [cartProductsCount, setCartProductsCount] = useState(null);
  const [total, setTotal] = useState(0);
  let userId = null; // Declare userId with a default value
  let cartId = null;
  const [categories, setCategories] = useState([]);

  //UPGRADE համոզվել արդյոք սա ապահով տարբերակ է
  if (localStorage.getItem('token')) {
    const myUser = jwtDecode(localStorage.getItem('token'));
    cartId = myUser.cartId;
    userId = myUser.id;
  }
  //UPGRADE

  const { user } = useContext(Context);
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const logOut = () => {
    localStorage.setItem('token', '')
    user.setUser({})
    user.setIsAuth(false)
    user.setIsAdmin(false)
    history(HOME_ROUTE)
  }


  useEffect(() => {
    // Define an async function to fetch cart products
    async function fetchCartData() {
      try {
        const cartData = await fetchCartProductsByCartId();
        const cartSum = cartData.reduce((sum, cartDataItem) => {
          const itemTotal = cartDataItem.price * cartDataItem.quantity;
          return sum + itemTotal;
        }, 0);
        setTotal(cartSum);
        // Assuming cartData contains the count of cart products, update the state
        setCartProductsCount(cartData.length); // Update with the actual data from your API
      } catch (error) {
        console.error('Error fetching cart products:', error);
      }
    }
    async function getCategories() {
      try {
        const data = await fetchCategories();
        setCategories(data)
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    // Call the fetchCartData function if user is AUTH
    if (user.isAuth) {
      fetchCartData();
    }
    getCategories()
  }, [user.isAuth]); // Empty dependency array ensures that the effect runs only once when the component mounts

  // console.log(user.isAdmin)
  // console.log(user.isAuth)

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/home">SHOPOZAVR</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Button className='btn-modal-catalog' variant='primary' onClick={handleShow}>Catalog</Button>
            {user.isAdmin ?
              <Nav className="me-auto">
                {/* <NavLink to={CART_ROUTE}>Cart</NavLink> */}
                <Button
                  className="mr-3"
                  variant={"outline-dark"}
                  onClick={() => history(ADMIN_ROUTE)}
                >
                  Admin Panel
                </Button>
                <Button
                  className="mr-3"
                  variant={"outline-dark"}
                  onClick={() => history(ADMIN_ROUTE + '/adding_page')}
                >
                  Adding Page
                </Button>
                <Button
                  variant={"outline-dark"}
                  onClick={() => logOut()}
                >
                  Logout
                </Button>
              </Nav>
              :
              <Nav className="main-nav me-auto">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <Button id="button-addon2">
                    <FaSearch />
                  </Button>
                </InputGroup>

                {user.isAuth ? (
                  // Show the elements when user is authenticated (isAuth is true)
                  <>
                    <Button className='btn-nav-icons' variant='outline-dark'>
                      <FaUser />
                    </Button>
                    <Button
                      className='btn-nav-icons'
                      variant='outline-dark'
                      onClick={() => history(FAVORITE_ROUTE + '/' + userId)}
                    >
                      <FaHeart />
                    </Button>
                    <NavbarCartComponent cartProductsCount={cartProductsCount} cartId={cartId} />

                    <span>{total} $</span>
                    <Button
                      variant={"outline-dark"}
                      onClick={() => logOut()}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  // Show the "Login" button when user is not authenticated (isAuth is false)
                  <Button
                    className='btn-nav-login'
                    onClick={() => history(LOGIN_ROUTE)}
                    variant={"outline-danger"}
                  >
                    Login
                  </Button>
                )}

                {/* <Button className='btn-nav-login' onClick={() => history(LOGIN_ROUTE)} variant={"outline-danger"}>Login</Button> */}
              </Nav>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <CatalogModal show={show} onHide={handleClose} categories={categories} />
    </>
  );
};

export default NavBar;