import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/SCSS/ProductCard.scss';
import { Context } from '..';
import { $authHost } from '../http';
import FavoriteHeart from './commonComponents/FavoriteHeart';
import AddCartRemove from './commonComponents/AddCartRemove';

const ProductCard = ({ product }) => {

    const { user } = useContext(Context);

    return (
        <div className='product-card'>
            <div className='card-title'>
            </div>
            <div className='card-body'>
                <Link to={`/product/${product.id}`}>
                    <img className='card-image' src={$authHost.defaults.baseURL + 'photos/products/' + product.img_main} alt={product.name} />
                </Link>
                {/* if user is login show heart */}
                {user.isAuth ? <FavoriteHeart productId={product.id}/> : ''}
            </div>
            <div className='card-footer'>
                <div className='card-information'>
                    <p className='product-name-text'>{product.name}</p>
                    <p className='product-price-text'>Price {product.price}</p>
                    <p className='product-price-text'>In Stock {product.quantity}</p>
                </div>
                <div>
                    <AddCartRemove productId={product.id}/>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
