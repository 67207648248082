import React, { useState } from 'react';
import '../../CSS/SCSS/commonComponents/PaymentMethod.scss';

const PaymentMethod = ({ onSelectPaymentMethod }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);

  const handlePaymentMethodChange = (method) => {
    setSelectedMethod(method);
    onSelectPaymentMethod(method); // Notify parent component of the selected method
  };

  return (
    <div className='payment-method'>
      {/* UPGRADE show which method is choosed  */}
      <p className={`payment-method ${selectedMethod ? 'text-success' : 'text-danger'}`}>Please choose a Payment Method:</p>
      <div className='payment-options'>
        <label>
          <input
            type='radio'
            name='paymentMethod'
            value='Cash'
            checked={selectedMethod === 'Cash'}
            onChange={() => handlePaymentMethodChange('Cash')}
          />
          Cash
        </label>
        <label>
          <input
            type='radio'
            name='paymentMethod'
            value='Card'
            checked={selectedMethod === 'Card'}
            onChange={() => handlePaymentMethodChange('Card')}
          />
          Credit Card
        </label>
        <label>
          <input
            type='radio'
            name='paymentMethod'
            value='Balance'
            checked={selectedMethod === 'Balance'}
            onChange={() => handlePaymentMethodChange('Balance')}
          />
          Balance
        </label>
      </div>
    </div>
  );
};

export default PaymentMethod;
